<template>
  <v-sheet height="90vh">
    <v-form>
      <v-container>
        <v-row>
          <v-col cols="12" sm="8" md="4">
            <v-dialog
              ref="dateField"
              v-model="dateModal"
              :return-value.sync="date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dateModal = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.dateField.save(date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" sm="8" md="4">
            <v-dialog
              ref="startTimeField"
              v-model="startTimeModal"
              :return-value.sync="time"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="startTimeFormated"
                  label="Start Time"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="startTimeModal"
                v-model="startTime"
                :max="endTime"
                full-width
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="startTimeModal = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.startTimeField.save(time)"
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" sm="8" md="4">
            <v-dialog
              ref="endTimeField"
              v-model="endTimeModal"
              :return-value.sync="time"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="endTimeFormated"
                  label="End Time"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="endTimeModal"
                v-model="endTime"
                :min="startTime"
                full-width
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="endTimeModal = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.endTimeField.save(time)"
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12">
            <v-chip-group
              v-model="teamMember"
              active-class="deep-purple--text text--accent-4"
              mandatory
              large
              column
            >
              <v-chip v-for="member in team" :key="member" :value="member">
                {{ member }}
              </v-chip>
            </v-chip-group>
          </v-col>
          <v-col cols="12">
            <v-btn block>Add</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-sheet>
</template>
<script>
export default {
  data: () => ({
    dateModal: false,
    startTimeModal: false,
    endTimeModal: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    startTime: null,
    endTime: null,
    teamMember: null,
    team: [
      "Joshua",
      "Mariette",
      "Kristoph",
      "Donna",
      "Heather",
      "Shiwanthi",
      "Kaylie",
    ],
  }),
  computed: {
    startTimeFormated() {
      var time = this.startTime;
      if (time === null) {
        return time;
      } else if (parseInt(time.substring(0, 2)) < 1) {
        return `12:${time.substring(3, 5)} AM`;
      } else if (parseInt(time.substring(0, 2)) < 10) {
        return `${time.substring(1, 2)}:${time.substring(3, 5)} AM`;
      } else if (parseInt(time.substring(0, 2)) < 12) {
        return `${time.substring(0, 2)}:${time.substring(3, 5)} AM`;
      } else if (parseInt(time.substring(0, 2)) < 13) {
        return `12:${time.substring(3, 5)} PM`;
      } else if (parseInt(time.substring(0, 2)) < 23) {
        return `${parseInt(time.substring(0, 2)) - 12}:${time.substring(
          3,
          5
        )} PM`;
      } else {
        return `${parseInt(time.substring(0, 2)) - 12}:${time.substring(
          3,
          5
        )} PM`;
      }
    },
    endTimeFormated() {
      var time = this.endTime;
      if (time === null) {
        return time;
      } else if (parseInt(time.substring(0, 2)) < 1) {
        return `12:${time.substring(3, 5)} AM`;
      } else if (parseInt(time.substring(0, 2)) < 10) {
        return `${time.substring(1, 2)}:${time.substring(3, 5)} AM`;
      } else if (parseInt(time.substring(0, 2)) < 12) {
        return `${time.substring(0, 2)}:${time.substring(3, 5)} AM`;
      } else if (parseInt(time.substring(0, 2)) < 13) {
        return `12:${time.substring(3, 5)} PM`;
      } else if (parseInt(time.substring(0, 2)) < 23) {
        return `${parseInt(time.substring(0, 2)) - 12}:${time.substring(
          3,
          5
        )} PM`;
      } else {
        return `${parseInt(time.substring(0, 2)) - 12}:${time.substring(
          3,
          5
        )} PM`;
      }
    },
  },
};
</script>
